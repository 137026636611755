import React from 'react'
import { Box, Heading, Text } from 'theme-ui'
import Img from "gatsby-image"

const TextImage = ({
    reverse,
    title,
    image,
    description
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: reverse ? ['column-reverse','column-reverse',"row-reverse"] : ['column-reverse','column-reverse',"row"],
                justifyContent: ['space-between'],
                alignItems: 'center',
                px: [4,5,6],
                mb: [70,70,130], 
                mt: [70,70,130],
                color: 'white',
                

                
            }}>
            <Box
                sx={{
                    width: ['100%','100%','50%'],
                    px: [1,5,0],
                    display: 'flex',
                    flexDirection: ['column'],
                    pl: reverse ? [0,4] : 0, 
                    pr: reverse ? 0 : [0,4], 
                    textAlign: ['center','center','inherit'],
                    mt: [4,4,0]

                    
                    
                }}>
                <Heading className="splittext"
                    sx={{
                        fontSize: [6,7,6],
                        color: 'white',
                        pb:'15px',
                        
                        
                    }}>
                    {title}
                </Heading>
                <Text
                className="splittext"
                    sx={{
                        fontSize: [3,6,4],
                        color: 'white',
                    }}>
                    {description}
                </Text>
            </Box>
            <Box
            className={reverse ? "textimage-img-reverse" : "textimage-img"}
                sx={{
                    width: ['100%','100%',400],
                    height: 400,
                    borderRadius: '20px',
                    overflow: 'hidden',
                    mb: [3, 0],

                    "& .gatsby-image-wrapper": {
                        height: '100%',
                        
                    }
                }}>
                <Img fluid={image.fluid} />
            </Box>
        </Box>
    )
}

export default TextImage

TextImage.defaultProps = {
    reverse: false
}