// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blueberry-js": () => import("./../../../src/pages/blueberry.js" /* webpackChunkName: "component---src-pages-blueberry-js" */),
  "component---src-pages-blueberry-rs-js": () => import("./../../../src/pages/blueberry.rs.js" /* webpackChunkName: "component---src-pages-blueberry-rs-js" */),
  "component---src-pages-blueberry-ru-js": () => import("./../../../src/pages/blueberry.ru.js" /* webpackChunkName: "component---src-pages-blueberry-ru-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-rs-js": () => import("./../../../src/pages/contact.rs.js" /* webpackChunkName: "component---src-pages-contact-rs-js" */),
  "component---src-pages-contact-ru-js": () => import("./../../../src/pages/contact.ru.js" /* webpackChunkName: "component---src-pages-contact-ru-js" */),
  "component---src-pages-farm-js": () => import("./../../../src/pages/farm.js" /* webpackChunkName: "component---src-pages-farm-js" */),
  "component---src-pages-farm-rs-js": () => import("./../../../src/pages/farm.rs.js" /* webpackChunkName: "component---src-pages-farm-rs-js" */),
  "component---src-pages-farm-ru-js": () => import("./../../../src/pages/farm.ru.js" /* webpackChunkName: "component---src-pages-farm-ru-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-rs-js": () => import("./../../../src/pages/index.rs.js" /* webpackChunkName: "component---src-pages-index-rs-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-wholesale-js": () => import("./../../../src/pages/wholesale.js" /* webpackChunkName: "component---src-pages-wholesale-js" */),
  "component---src-pages-wholesale-rs-js": () => import("./../../../src/pages/wholesale.rs.js" /* webpackChunkName: "component---src-pages-wholesale-rs-js" */),
  "component---src-pages-wholesale-ru-js": () => import("./../../../src/pages/wholesale.ru.js" /* webpackChunkName: "component---src-pages-wholesale-ru-js" */)
}

