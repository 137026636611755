import React, {useRef, useEffect, useState} from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
// import "../components/locomotiveScroll.css"
// import Scroll from "../components/locomotiveScroll"
import {Box, jsx} from "theme-ui"
import {keyframes} from "@emotion/core"
import noiseImg from "../images/noise.png"
// import ScrollTriggerComp from '../components/scrolltrigger'
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import LangProvider from '../components/Context/LangProvider'
import { useScrollPosition } from "../components/helpers/useScrollPosition"
/** @jsx jsx */


export default (props) => {
    const {location, children, } = props
    const [sticky, setSticky] = useState(true)

    useScrollPosition(
        ({ prevPos, currPos }) => {
          const isShow = currPos.y > prevPos.y
          if (isShow !== sticky) {
              console.log('??')
              setSticky(isShow)
          }
        },
        [sticky]
      )

    const anim = keyframes`
        0%, 100% { transform:translate(0, 0) }
        10% { transform:translate(-5%, -10%) }
        20% { transform:translate(-15%, 5%) }
        30% { transform:translate(7%, -25%) }
        40% { transform:translate(-5%, 25%) }
        50% { transform:translate(-15%, 10%) }
        60% { transform:translate(15%, 0%) }
        70% { transform:translate(0%, 15%) }
        80% { transform:translate(3%, 35%) }
        90% { transform:translate(-10%, 10%) }
    `

    console.log('location', location)

    let headerRef = useRef(null)

    useEffect(() => {
        gsap.registerPlugin(SplitText);
        // tlHero.from(headerRef, {y: '-100%', ease: "expo", duration: 2,})

    }, [])

    return (
        <Box 
        className="layoutWrap"
        sx={{
            bg: 'primaryBlue',
            overflow: 'hidden'
        }}>
             <LangProvider location={location}>
            <Header
            sticky={sticky}
            sx={{position: 'relative', zIndex:10,}} 
            ref={el => headerRef.current = el}></Header>

            <Box 
            className='noise'
            sx={{
                '::after': {
                    content: '"asd"',
                    backgroundImage: `url(${noiseImg})`,
                    animation: `${anim} 8s steps(10) infinite`,
                    height: '300%',
                    width: '300%',
                    opacity:0.4,
                    position: 'fixed',
                    left: '-50%',
                    top: '-100%',
                    zIndex: 1,
                }
            }}></Box>
            {/* <ScrollTriggerComp callbacks={location}></ScrollTriggerComp> */}
            {/* <Scroll callbacks={location} /> */}
            <Box sx={{position: 'relative', zIndex:10}} id="container">{children}</Box>
            <Footer></Footer>
            </LangProvider>
        </Box>
    )
}


