export default {
    breakpoints: ['40em','52em','64em','80em','85em'],
    space: [
      0,
      4,
      8,
      16,
      32,
      64,
      128,
      256,
      512
    ],
    fonts: {
      body: 'Recoleta, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading: 'Recoleta',
      monospace: 'Menlo, monospace'
    },
    fontSizes: [
      12,
      14,
      16,
      20,
      24,
      32,
      48,
      64,
      96
    ],
    fontWeights: {
      body: 300,
      heading: 500,
      bold: 700
    },
    lineHeights: {
      body: 1.2,
      heading: 1,
    },
    colors: {
      text: '#000',
      background: '#fff',
      primaryBlue: '#10203C',
      secondaryBlue: '#374E70',
      muted: '#f6f6f6',
      primaryPink:'#F9CFC1',


    },
    buttons: {
        primary: {
            bg:"white",
            borderRadius:'43px',
            px: 4,
            py: 3,
            color: '#213B6A',
            fontSize: [3,"25px"],
            fontWeight: 500,
            border: '1px solid white',
            cursor: 'pointer',
            transition: '0.25s all ease-in-out',
            '&:hover': {
              bg: 'rgba(255,255,255,0.7)',
              // color: 'white',
            }

        }
    },
    styles: {
      root: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body'
      },
      h1: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 5
      },
      h2: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 4
      },
      h3: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 3
      },
      h4: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 2
      },
      h5: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 1
      },
      h6: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 0
      },
      p: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: 'body'
      },
      a: {
        color: 'primary'
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit'
        }
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit'
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      img: {
        maxWidth: '100%'
      }
    }
  }