import React from 'react'
import TextImage from '../textimage'

export const handleBenefits = benefits => {
    return benefits.map((x,index) => {
    const reverse= index % 2 !== 0 ? true : false
    return <TextImage 
    reverse={reverse}
    title={x.title}
    image={x.image}
    description={x.description}></TextImage>}
    )

}


export  const handleLinkLanguage = (url,currentLang) => {
    if (currentLang == "en") return `${url}`
    return `/${currentLang}${url}`
}

export const handleBlueberryLabel = (currentLang) => {
    if (currentLang == "en") return "Blueberry"
    if (currentLang == "ru") return "Голубика"
    if (currentLang == "rs") return "Borovnice"
}

export const handleFarmLabel = (currentLang) => {
    if (currentLang == "en") return "Farm"
    if (currentLang == "ru") return "Ферма"
    if (currentLang == "rs") return "Farma"
}

export const handleWholesaleLabel = (currentLang) => {
    if (currentLang == "en") return "Wholesale"
    if (currentLang == "ru") return "Оптовая торговля"
    if (currentLang == "rs") return "Veleprodaja"
}

export const handleContactLabel = (currentLang) => {
    if (currentLang == "en") return "Contact"
    if (currentLang == "ru") return "Kонтакт"
    if (currentLang == "rs") return "Kontakt"
}

export const handleFarmBoxButtonLabel = (currentLang) => {
    if (currentLang == "en") return "Visit the farm"
    if (currentLang == "ru") return "Посетить ферму"
    if (currentLang == "rs") return "Pogledaj farmu"
}