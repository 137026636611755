import React, { useState, useContext } from 'react'
import { Box, Heading, Text } from 'theme-ui'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
/** @jsx jsx  */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from 'gatsby'
import {
    handleLinkLanguage,
    handleBlueberryLabel,
    handleFarmLabel,
    handleWholesaleLabel,
    handleContactLabel,
} from "./helpers"
import LangContext from "./Context/LangContext"

const Footer = () => {
    const [isNavOpen, toggleNavOpen] = useState(false)
    const data = useStaticQuery(graphql`
    query FooterQuery {
    allDatoCmsFooter {
        nodes {
            phone
            partnerEn
            email
            descriptionEn
            address
            copyEn
            termsEn
        }
    }
    allDatoCmsGlobalImage {
        nodes {
            gap {
                fluid {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
  }`)

    const {
        setPage, currentPage, setLang, currentLang
    } = useContext(LangContext)

    const {
        descriptionEn,
        phone,
        address,
        email,
        partnerEn,
        copyEn,
        termsEn,
    } = data.allDatoCmsFooter.nodes[0]

    const {
        gap
    } = data.allDatoCmsGlobalImage.nodes[0]

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                bg: 'primaryBlue',
               

            }}>
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    // bg: 'primaryBlue',
                    px: [0,6],
                    py: [5,5],
                    justifyContent: 'space-between',
                    alignItems: ['center','flex-end'],
                    zIndex: 1,
                    flexDirection: ['column', 'column', 'row'],
                    
                }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        zIndex: 1,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            pb: 3,
                            justifyContent: ['center', 'center', 'start']


                        }}>
                        {/* logo */}
                        <svg width="50" height="49" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.1215 85.3961C18.8608 77.8558 24.4912 60.9655 23.955 58.251C23.4187 55.5364 17.2522 56.1397 17.2522 56.1397C14.3517 56.983 11.2853 56.7314 8.52432 55.4236C5.76331 54.1159 3.46049 51.8242 1.9698 48.901C-5.0011 35.0267 7.86825 22.359 19.6652 25.0735C19.6652 25.0735 14.8392 9.99285 23.955 3.35736C23.955 3.35736 37.0924 -8.40555 54.7878 12.1041L60.4181 17.5332C63.8632 14.3731 68.1721 12.6473 72.6172 12.6473C77.0623 12.6473 81.3713 14.3731 84.8163 17.5332C95.5408 29.2961 82.6714 45.2816 79.1859 47.6945C81.3947 48.846 83.3561 50.521 84.9358 52.6048C86.5154 54.6885 87.676 57.1318 88.3381 59.7671C89.0002 62.4025 89.148 65.1677 88.7715 67.8734C88.395 70.5791 87.5031 73.1612 86.1569 75.4429C85.1963 77.8203 83.7504 79.909 81.9368 81.539C80.1232 83.169 77.9932 84.2944 75.72 84.8234C73.4468 85.3525 71.0946 85.2704 68.855 84.5838C66.6153 83.8971 64.5514 82.6254 62.8312 80.8719C60.8774 78.6363 58.3422 77.1575 55.5921 76.6494C52.911 77.2526 42.7228 93.8413 30.1215 85.3961Z" fill="white" />
                        </svg>
                    </Box>



                    <Box as='ul'
                        sx={{
                            display: 'flex',
                            position: ['relative'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            flexDirection: ['column', 'column'],

                            width: ['auto', 'auto'],
                            height: ['auto', '100%'],



                            '& a': {
                                color: 'white',
                                fontSize: [50, 70, 'inherit'],
                                textDecoration: 'none',
                                '&:hover': {
                                    color: 'gray',
                                },
                                '&.active': {
                                    color: 'gray',
                                },
                            },

                            '& li': {
                                fontSize: 6,
                                listStyle: 'none',
                                pl: 0,
                                textAlign: ['center','left'],

                            }
                        }}>

                        <li><Link to={handleLinkLanguage('/blueberry',currentLang)} activeClassName='active'>{handleBlueberryLabel(currentLang)}</Link></li>
                        <li><Link to={handleLinkLanguage('/farm',currentLang)} activeClassName='active'>{handleFarmLabel(currentLang)}</Link></li>
                        <li><Link to={handleLinkLanguage('/wholesale',currentLang)} activeClassName='active'>{handleWholesaleLabel(currentLang)}</Link></li>
                        <li><Link to={handleLinkLanguage('/contact',currentLang)} activeClassName='active'>{handleContactLabel(currentLang)}</Link></li>

                    </Box>
                    <Box
                        sx={{
                            pt: 3,
                            height: 1,
                            width: '100%',
                            borderBottom: '1px solid white',
                            mb: [4, 5, 0],


                        }}>

                    </Box>


                </Box>
                {/* box 2 desno */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: ['auto', '100%', '35%'],
                        alignItems: ['center', 'flex-start'],
                    }}>

                    <Box
                        sx={{
                            display: 'flex',
                            mb: 3,

                        }}>
                        {/* primeberry */}
                        <Link to='/'>
                            <svg
                                sx={{
                                    width: [300, 300, 200],
                                    height: [100, 100, 47],
                                }} viewBox="0 0 199 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.0797 20.4741C23.0797 27.148 17.4913 30.4284 10.2265 30.4284H8.49414L7.76766 35.1228C7.76766 36.6498 9.16474 36.8761 9.16474 38.0638C9.16474 39.2515 8.43826 39.1384 7.20883 39.1384H1.62051C0.446965 39.1384 -0.223633 39.1384 -0.223633 38.1203C-0.223633 37.1023 1.84404 36.6498 2.06758 35.1228L4.80585 18.1552C4.80585 16.5716 3.40877 16.515 3.40877 15.2708C3.40877 14.0265 4.35879 14.083 5.53233 14.083H13.4677C19.1678 13.8002 23.0797 16.1757 23.0797 20.4741ZM16.7649 21.266C16.8204 20.6446 16.7452 20.0183 16.544 19.4286C16.3429 18.8388 16.0204 18.2989 15.5979 17.8446C15.1754 17.3902 14.6624 17.0318 14.093 16.7928C13.5235 16.5539 12.9104 16.44 12.2942 16.4585H11.7354C10.7854 16.4585 10.4501 17.0241 10.3383 17.9856L8.88533 27.8267H10.5059C11.3609 27.8798 12.217 27.7446 13.0154 27.4305C13.8137 27.1163 14.5352 26.6307 15.1302 26.007C15.7251 25.3834 16.1794 24.6365 16.4616 23.818C16.7438 22.9995 16.8473 22.1288 16.7649 21.266Z" fill="white" />
                                <path d="M24.9237 37.1023L26.5443 26.2996C26.9913 23.6414 24.8678 24.3201 24.9237 22.6799C24.9795 21.0397 27.5502 19.9651 29.562 20.0216C31.5738 20.0782 32.3002 21.2659 31.9091 23.302L31.2385 26.7521C32.6914 21.7184 34.815 19.9651 36.8268 19.9651C37.1912 19.9131 37.5625 19.9447 37.9133 20.0574C38.2641 20.17 38.5855 20.361 38.8537 20.6161C39.1218 20.8712 39.33 21.184 39.4628 21.5314C39.5956 21.8788 39.6496 22.252 39.6209 22.6233C39.6513 23.0068 39.6056 23.3926 39.4865 23.7579C39.3674 24.1233 39.1773 24.4608 38.9273 24.7506C38.6774 25.0405 38.3727 25.2768 38.0311 25.4457C37.6896 25.6146 37.3181 25.7126 36.9385 25.734C36.1003 25.734 35.7091 25.734 35.0944 25.734C32.8032 25.734 31.3502 30.3152 30.5679 35.0661L30.1767 37.6113C30.1566 37.8676 30.0845 38.1169 29.9648 38.3436C29.8451 38.5704 29.6804 38.7697 29.481 38.929C29.2816 39.0883 29.0519 39.2042 28.8062 39.2694C28.5605 39.3346 28.3041 39.3478 28.0531 39.308H26.4325C25.2031 39.1949 24.7001 38.4597 24.9237 37.1023Z" fill="white" />
                                <path d="M41.2978 35.9146L42.8066 26.2431C43.2537 23.7545 41.186 24.3201 41.186 22.5668C41.186 20.8135 43.3655 20.0217 45.7685 20.0217C48.1714 20.0217 48.9538 21.266 48.6185 23.6414L46.9979 33.2564C46.6067 35.8015 48.6744 35.1793 48.6744 36.9892C48.6744 38.7991 46.6626 39.5909 44.2596 39.5909C41.8566 39.5909 40.9625 38.29 41.2978 35.9146Z" fill="white" />
                                <path d="M83.2099 36.9891C83.2099 38.29 81.1982 39.5343 78.7952 39.5343C76.3922 39.5343 75.4422 38.4031 75.8892 35.9145L77.2304 27.4873C77.5657 25.3381 77.2304 24.1504 75.6657 24.1504C72.9833 24.1504 70.8039 29.1275 70.0774 34.8965L69.6862 37.4416C69.6862 38.6293 68.848 39.1383 67.5627 39.1383H65.8862C64.6567 39.1383 64.2097 38.4596 64.4332 37.1022L65.9979 27.4873C66.2773 25.3381 65.9979 24.1504 64.3773 24.1504C61.5832 24.1504 59.4037 29.8062 58.4537 35.4621L58.1743 37.2154C58.1743 38.4031 57.336 38.9121 56.0507 38.9121H54.2625C53.033 38.9121 52.586 38.2334 52.7536 36.876L54.486 26.0734C54.8772 23.4151 52.8095 24.0938 52.8095 22.4536C52.8095 20.8134 55.4919 19.7388 57.6713 19.7954C59.8508 19.8519 60.5773 21.0397 60.1302 23.0758L59.5155 26.1865C61.192 21.5487 63.3155 19.7388 66.3891 19.7388C69.4627 19.7388 71.3627 22.1708 70.9715 26.1865C72.7039 21.5487 74.8275 19.7388 77.8452 19.7388C80.8629 19.7388 83.4335 22.3405 82.3717 27.4308L81.5334 33.0866C81.1423 35.8014 83.2099 35.2924 83.2099 36.9891Z" fill="white" />
                                <path d="M102.098 33.9351C101.727 35.6298 100.767 37.1333 99.3926 38.1714C98.0181 39.2096 96.3208 39.7134 94.6099 39.5909C93.4821 39.6583 92.3531 39.4812 91.2984 39.0714C90.2436 38.6617 89.2875 38.0287 88.494 37.2148C87.7004 36.401 87.0877 35.425 86.6968 34.3522C86.306 33.2794 86.1459 32.1345 86.2275 30.994C86.1209 29.5709 86.3016 28.1408 86.7585 26.7906C87.2154 25.4404 87.939 24.1984 88.8852 23.1403C89.8314 22.0821 90.9805 21.2298 92.2625 20.6354C93.5445 20.0409 94.9326 19.7167 96.3423 19.6824C100.198 19.6824 103.16 21.5488 103.16 24.8857C103.16 28.2227 99.3041 31.9555 92.8217 31.9555H91.8717C92.0102 32.9647 92.5175 33.8847 93.2934 34.5339C94.0693 35.1831 95.0575 35.5143 96.0629 35.4622C96.8297 35.5379 97.6035 35.4402 98.3284 35.1761C99.0532 34.912 99.711 34.4881 100.254 33.9351C100.36 33.7613 100.508 33.618 100.684 33.5189C100.86 33.4199 101.058 33.3684 101.26 33.3695C101.443 33.3487 101.628 33.3941 101.781 33.4976C101.934 33.601 102.047 33.756 102.098 33.9351ZM91.5922 29.8063H92.5423C93.2588 29.8697 93.9805 29.7802 94.6607 29.5437C95.3409 29.3071 95.9645 28.9287 96.4911 28.433C97.0178 27.9373 97.4359 27.3352 97.7183 26.6657C98.0007 25.9962 98.1411 25.2742 98.1306 24.5464C98.1306 23.0193 97.46 22.2275 96.1188 22.2275C93.3246 22.6234 91.5922 26.0735 91.5922 29.9194V29.8063Z" fill="white" />
                                <path d="M125.737 31.1071C125.737 36.7629 121.49 39.1384 115.399 39.1384H106.178C104.837 39.1384 104.446 38.799 104.446 38.0638C104.446 37.3285 106.458 36.8761 106.793 34.8965L109.364 17.929C109.699 16.006 108.022 16.0626 108.022 14.8183C108.022 13.574 108.637 13.6871 109.755 13.6871H118.081C124.061 13.6871 126.296 16.0626 126.296 19.343C126.239 20.807 125.712 22.2122 124.794 23.3453C123.876 24.4783 122.618 25.2773 121.211 25.6209C122.474 25.8683 123.614 26.5491 124.439 27.5486C125.263 28.5481 125.722 29.805 125.737 31.1071ZM120.149 31.1071C120.149 28.2226 118.417 27.148 115.567 27.148H113.108L111.766 35.349C111.766 36.1408 111.766 36.5367 112.828 36.5367H115.064C115.733 36.5541 116.399 36.4297 117.019 36.1713C117.638 35.9129 118.197 35.5264 118.66 35.0364C119.123 34.5465 119.48 33.9639 119.707 33.3262C119.935 32.6885 120.028 32.0097 119.981 31.3333L120.149 31.1071ZM114.561 17.7593L113.443 24.5463H115.846C116.505 24.6225 117.173 24.551 117.803 24.3368C118.432 24.1226 119.007 23.771 119.487 23.3069C119.967 22.8427 120.34 22.2774 120.58 21.6511C120.82 21.0247 120.921 20.3526 120.875 19.6823C120.877 19.2267 120.785 18.7757 120.605 18.358C120.425 17.9404 120.161 17.5653 119.83 17.2567C119.498 16.9481 119.107 16.7127 118.681 16.5655C118.255 16.4184 117.803 16.3626 117.355 16.4019H116.014C114.84 16.4019 114.561 16.9675 114.449 17.9856L114.561 17.7593Z" fill="white" />
                                <path d="M144.626 33.935C144.255 35.6298 143.295 37.1332 141.92 38.1714C140.546 39.2095 138.849 39.7133 137.138 39.5909C136.002 39.6905 134.858 39.5412 133.785 39.1533C132.711 38.7653 131.733 38.1478 130.916 37.3428C130.099 36.5377 129.464 35.564 129.052 34.488C128.641 33.4119 128.463 32.2588 128.532 31.1071C128.425 29.684 128.606 28.2539 129.063 26.9037C129.52 25.5535 130.243 24.3115 131.189 23.2533C132.136 22.1951 133.285 21.3429 134.567 20.7484C135.849 20.154 137.237 19.8297 138.647 19.7954C142.502 19.7954 145.464 21.6619 145.464 24.9988C145.464 28.3358 141.608 32.0686 135.126 32.0686H134.176C134.314 33.0778 134.822 33.9978 135.598 34.647C136.374 35.2962 137.362 35.6274 138.367 35.5752C139.179 35.6723 140.001 35.5744 140.768 35.2894C141.535 35.0044 142.225 34.5404 142.782 33.935C142.887 33.7613 143.035 33.618 143.212 33.5189C143.388 33.4198 143.586 33.3684 143.788 33.3694C143.971 33.3487 144.155 33.394 144.309 33.4975C144.462 33.601 144.575 33.7559 144.626 33.935ZM134.12 29.9194H135.07C135.787 29.9828 136.508 29.8933 137.188 29.6567C137.869 29.4202 138.492 29.0418 139.019 28.5461C139.546 28.0503 139.964 27.4483 140.246 26.7788C140.528 26.1093 140.669 25.3873 140.658 24.6595C140.658 23.1324 139.988 22.3406 138.647 22.3406C135.852 22.6234 134.12 26.0734 134.12 29.9194Z" fill="white" />
                                <path d="M148.761 37.1023L150.438 26.2997C150.829 23.6414 148.761 24.3201 148.761 22.6799C148.761 21.0397 151.444 19.9651 153.4 20.0217C155.356 20.0782 156.194 21.266 155.803 23.3021L155.132 26.7521C156.529 21.7184 158.709 19.9651 160.72 19.9651C161.077 19.9389 161.435 19.9916 161.769 20.1195C162.103 20.2475 162.406 20.4476 162.656 20.7061C162.906 20.9645 163.097 21.2749 163.216 21.6158C163.336 21.9567 163.38 22.3198 163.347 22.6799C163.377 23.0634 163.332 23.4492 163.212 23.8145C163.093 24.1799 162.903 24.5174 162.653 24.8073C162.403 25.0971 162.099 25.3334 161.757 25.5023C161.415 25.6712 161.044 25.7692 160.664 25.7906C159.826 25.7906 159.435 25.7906 158.82 25.7906C156.529 25.7906 155.076 30.3719 154.294 35.1228L153.903 37.6679C153.875 37.9266 153.797 38.177 153.671 38.404C153.545 38.631 153.375 38.8298 153.172 38.9884C152.968 39.147 152.735 39.2622 152.486 39.3268C152.237 39.3915 151.977 39.4044 151.723 39.3646H150.103C149.059 39.2138 148.612 38.4597 148.761 37.1023Z" fill="white" />
                                <path d="M165.023 37.1023L166.644 26.2997C167.091 23.6414 164.967 24.3201 165.023 22.6799C165.079 21.0397 167.65 19.9651 169.661 20.0217C171.673 20.0782 172.4 21.266 172.009 23.3021L171.338 26.7521C172.791 21.7184 174.97 19.9651 176.926 19.9651C177.283 19.9389 177.64 19.9916 177.975 20.1195C178.309 20.2475 178.612 20.4476 178.862 20.7061C179.112 20.9645 179.303 21.2749 179.422 21.6158C179.542 21.9567 179.586 22.3198 179.553 22.6799C179.591 23.065 179.551 23.454 179.435 23.8228C179.319 24.1915 179.129 24.5323 178.878 24.8239C178.627 25.1155 178.319 25.3518 177.973 25.518C177.628 25.6843 177.253 25.7771 176.87 25.7906C176.032 25.7906 175.641 25.7906 175.026 25.7906C172.735 25.7906 171.282 30.3719 170.5 35.1228L170.109 37.6679C170.088 37.9242 170.016 38.1735 169.897 38.4003C169.777 38.627 169.612 38.8263 169.413 38.9856C169.213 39.1449 168.984 39.2608 168.738 39.326C168.492 39.3912 168.236 39.4044 167.985 39.3646H166.532C165.303 39.1384 164.855 38.4597 165.023 37.1023Z" fill="white" />
                                <path d="M199 23.5848C199 33.0301 190.562 47 181.453 47C178.491 47 176.759 45.6992 176.759 43.8893C176.751 43.607 176.799 43.326 176.899 43.0624C176.999 42.7988 177.149 42.5576 177.341 42.3528C177.533 42.1479 177.763 41.9834 178.018 41.8686C178.273 41.7537 178.547 41.6908 178.826 41.6835C181.062 41.6835 180.67 43.9458 182.906 43.9458C184.485 43.6675 185.99 43.0616 187.327 42.1661C188.664 41.2707 189.803 40.105 190.673 38.7425C189.998 39.0129 189.276 39.1474 188.55 39.1384C185.588 39.1384 183.8 37.3285 183.353 33.4825L182.57 24.8857C182.57 22.793 180.67 23.1889 180.67 21.8315C180.67 20.4741 181.341 20.5307 182.794 20.5307H188.382C189.5 20.5307 189.947 20.9266 189.947 21.6618C189.947 23.2455 188.326 22.6233 188.438 24.8291L189.109 33.2563C189.109 35.1793 189.891 35.9146 191.009 35.9146C192.126 35.9146 193.579 34.8965 194.753 31.8424C195.307 30.3801 195.591 28.8273 195.591 27.2611C195.591 24.6594 193.579 24.6029 193.579 22.4537C193.579 22.1171 193.646 21.784 193.777 21.4745C193.907 21.1649 194.098 20.8854 194.339 20.6527C194.579 20.4201 194.863 20.2392 195.175 20.121C195.486 20.0029 195.818 19.9498 196.15 19.9651C197.938 19.9651 199 21.2659 199 23.5848Z" fill="white" />
                                <path d="M39.3974 16.006C37.0504 14.5921 38.2239 11.4248 38.1121 10.9158C38.0004 10.4068 36.7151 10.5199 36.7151 10.5199C36.1105 10.678 35.4714 10.6308 34.8959 10.3856C34.3204 10.1404 33.8404 9.71064 33.5297 9.16247C32.0767 6.56078 34.7591 4.18533 37.218 4.69436C37.218 4.69436 36.2121 1.86644 38.1121 0.622155C38.1121 0.622155 40.8504 -1.58362 44.5387 2.26235L45.7122 3.2804C46.4303 2.68782 47.3284 2.36421 48.2549 2.36421C49.1814 2.36421 50.0796 2.68782 50.7976 3.2804C53.0329 5.48617 50.3505 8.48377 49.6241 8.93623C50.0844 9.15216 50.4933 9.46626 50.8225 9.857C51.1518 10.2477 51.3937 10.7059 51.5317 11.2001C51.6697 11.6943 51.7005 12.2128 51.622 12.7202C51.5435 13.2275 51.3576 13.7117 51.077 14.1396C50.8768 14.5854 50.5754 14.9771 50.1974 15.2827C49.8194 15.5884 49.3755 15.7994 48.9016 15.8986C48.4278 15.9979 47.9376 15.9825 47.4708 15.8537C47.0039 15.7249 46.5738 15.4865 46.2152 15.1577C45.808 14.7384 45.2795 14.4611 44.7063 14.3658C44.1475 14.479 42.024 17.5897 39.3974 16.006Z" fill="white" />
                            </svg>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ['center','flex-start'],
                            textAlign: ['center', 'left'],
                        }}>
                        <Text
                            sx={{
                                fontSize: [4, 5, 2],
                                color: 'white',
                                pb: 2,
                                width: '45%',

                            }}>
                            {address}
                        </Text>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 2,
                        }}>
                        <a href={`tel:${phone}`}
                            sx={{
                                fontSize: [4, 5, 2],
                                color: 'primaryPink',
                                textDecoration: 'none',

                            }}>
                            {phone}
                        </a>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}>
                        <a href={`mailto:${email}`}
                            sx={{
                                fontSize: [4, 5, 2],
                                color: 'primaryPink',
                                pb: 4,
                                textDecoration: 'none',

                            }}>
                            {email}
                        </a>
                    </Box>
                    {/* <Box
                        sx={{
                            bg: 'secondaryBlue',
                            height: '50px',
                            mb: 4,
                        }}>

                    </Box> */}
                    {/* <Box
                        sx={{
                            fontSize: [3, 4, 1],
                            color: 'white',
                            pb: 5,


                        }}>
                        {descriptionEn}
                    </Box> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: ['center', 'flexx-start'],

                        }}>
                        <Box>
                            <Text
                                sx={{
                                    fontSize: [3, 4, 2],
                                    color: 'white',
                                    mb: 3,
                                }}>
                                {partnerEn}
                            </Text>
                        </Box>
                        <Box
                            sx={{
                                position: 'relative',
                                zIndex: 2,
                                width: 100,
                                height: 100,
                                borderRadius: '50%',
                                bg: 'white',
                                ml: 2,
                                pt: '12px'

                            }}>
                            <Img fluid={gap.fluid} imgStyle={{ width: '100%', height: '100%', objectFit: 'contain', }} />
                        </Box>



                    </Box>

                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 4,
                    mb: 4,

                }}>
                <Text
                    sx={{
                        fontSize: 2,
                        color: 'white',
                        pb: 4,

                    }}>
                    {copyEn}
                </Text>
                {/* <Text
                    sx={{
                        fontSize: 2,
                        color: 'white',
                    }}>
                    {termsEn}
                </Text> */}
            </Box>
        </Box>

    )
}

export default Footer