import React, { useState, useEffect, useContext } from 'react'
import LangContext from './LangContext'
import axios from 'axios'
import { Input } from 'theme-ui'
import { navigate } from 'gatsby'

const LangProvider = ({ children, location }) => {

const [currentLang, setLang] = useState("")
const [currentPage, setPage] = useState("")

// Set language
useEffect(() => {
  //Get language and set it
  console.log('<==><==>', window.navigator.languages.length)
  if(window && window.navigator && window.navigator.languages[1] || window && window.navigator && window.navigator && window.navigator.language) {
    const currentLanguage = (window) => {
      if(currentLang) return currentLang
      console.log('__________________________________________________________', location.pathname.slice(1, 3))
      // if(location && location.pathname && location.pathname.includes("/")) return location.pathname.slice(1, 3)
      // if(window && window.navigator && window.navigator && window.navigator.language) return  window.navigator.language.slice(0, 2)
      if(window && window.navigator && window.navigator.languages[window.navigator.languages.length]) return window.navigator.languages[window.navigator.languages.length - 1]
      return "en"
    }
    console.log('lang++++', lang)
    const lang = currentLanguage(window)
    setLang(lang)
  }


  return 
},[])


// Navigate if wrong URL for current language
useEffect(() => {
  if(currentPage && currentLang) {
  if(currentPage === "index") {
    if(currentLang !== "en") navigate(`/${currentLang}`)
    currentLang === "en" && navigate(`/`)
  } else {
    if(currentLang !== "en") navigate(`/${currentLang}/${currentPage}`)
    currentLang === "en" && navigate(`/${currentPage}`)
  }
  }  
},[currentLang])

  return (
    <LangContext.Provider
      value={{
        setLang,
        currentLang,
        currentPage,
        setPage,
      }}
    >
      {children}
    </LangContext.Provider>
  )
}

export default LangProvider